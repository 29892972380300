import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import ReactSVG from 'react-svg'
import NavList from './NavList'
import Burger from './Burger'

const Navbar = ({ data }) => {
  const navBar = data.navBar
  const pathname = data.pathname
  const open = data.open
  const setOpen = data.setOpen

  return (
    <>
      <header
        className={`${navBar ? 'header header-active' : 'header'} ${
          pathname == '/' ? 'header-main' : ''
        }`}
        id='header'>
        <nav className='navigation'>
          <div className='container-lg'>
            <div className='navigation__inner flex items-center justify-between'>
              <Link className='navigation__logo' to='/'>
                {pathname == '/' ? (
                  <div>
                    <img
                      alt='Bloom Collective logo'
                      src={
                        navBar ? '/img/svg/logo.svg' : '/img/svg/logo-white.svg'
                      }
                    />
                  </div>
                ) : (
                  <div>
                    <img alt='Bloom Collective logo' src='/img/svg/logo.svg' />
                  </div>
                )}
              </Link>
              <Link className='navigation__logo--mobile' to='/'>
                {pathname == '/' ? (
                  <ReactSVG
                    src={
                      navBar
                        ? open
                          ? '/img/svg/logo-white.mobile.svg'
                          : '/img/svg/logo.mobile.svg'
                        : open
                        ? '/img/svg/logo-white.mobile.svg'
                        : '/img/svg/logo-white.mobile.svg'
                    }
                  />
                ) : (
                  <ReactSVG
                    src={
                      open
                        ? '/img/svg/logo-white.mobile.svg'
                        : '/img/svg/logo.mobile.svg'
                    }
                  />
                )}
              </Link>

              {pathname == '/' ? (
                <>
                  <NavList main={true} open={open} setOpen={setOpen} />
                  <Burger
                    main={true}
                    navBar={navBar}
                    setOpen={setOpen}
                    open={open}
                  />
                </>
              ) : (
                <>
                  <NavList open={open} setOpen={setOpen} />
                  <Burger navBar={navBar} setOpen={setOpen} open={open} />
                </>
              )}
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Navbar
