import React from 'react'
import { Link } from 'gatsby'
import ReactSVG from 'react-svg'

const Footer = class extends React.Component {
  render() {
    return (
      //pt-32
      <footer className='footer'>
        <div className='container-lg'>
          <div className='footer__inner'>
            <Link to='/' className='footer__brand'>
              <ReactSVG
                style={{
                  width: '53px',
                  height: '74px',
                }}
                src='img/svg/logo-white.svg'
              />
            </Link>
            <div className='footer__nav'>
              <div className='footer-list-wrap text-m '>
                <h6 className='footer-list__title'>Menu</h6>
                <div className='footer-list '>
                  <Link to='/' className='footer-list__item'>
                    Home
                  </Link>
                  <Link to='/team' className='footer-list__item'>
                    Team
                  </Link>
                  <Link to='/blog' className='footer-list__item'>
                    Blog
                  </Link>
                  <Link to='/contact' className='footer-list__item'>
                    Contact
                  </Link>
                </div>
              </div>

              <div className='footer-list-wrap text-m '>
                <h6 className='footer-list__title'>Follow Us</h6>
                <div className='footer-list'>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.instagram.com'
                    className='footer-list__item'>
                    Instagram
                  </a>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.facebook.com'
                    className='footer-list__item'>
                    Facebook
                  </a>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://www.twitter.com'
                    className='footer-list__item'>
                    Twitter
                  </a>
                </div>
              </div>

              <div className='footer-list-wrap text-m '>
                <h6 className='footer-list__title'>Important Stuff</h6>
                <div className='footer-list'>
                  <Link to='/privacy' className='footer-list__item'>
                    Privacy Policy
                  </Link>
                </div>
              </div>

              <div className='footer-list-wrap'>
                <h6 className='footer-list__title'>Contact Us</h6>
                <div className='footer-list'>
                  <a
                    href='mailto:connect@bloom-collective.com.au'
                    className='footer-list__item'>
                    connect@bloom-collective.com.au
                  </a>
                  <Link
                    className='footer-list__item footer-list__item--inline footer-list__item--accent mt-6'
                    to='/contact'>
                    <span>Use Contact Form</span>
                    <ReactSVG src='/img/svg/arrow.svg'></ReactSVG>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='container-lg'>
            <div className='flex justify-end pt-12 md:pt-24 text-center md:text-right pb-2 md:pb-6'>
              <p className='opacity-50 text-xs text-white mb-0 w-full'>
                Created by{' '}
                <a
                  className='text-yellow'
                  rel='noreferrer'
                  target='_blank'
                  href='https://cleancommit.io'>
                  Clean Commit
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
