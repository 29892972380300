import React from "react";
import { Link } from "gatsby";
import ReactSVG from "react-svg";

const NavList = ({ main, open, setOpen }) => {
  return (
    <>
      <div
        className={
          "navigation-list" +
          " " +
          (open ? "navigation-open" : "navigation-closed")
        }
      >
        <ReactSVG src="/img/svg/logo-white.svg" className="logo-nav" />
        <Link
          onClick={() => setOpen(false)}
          to="/"
          className={main ? "nav-link-main" : "nav-link"}
        >
          Projects
        </Link>
        <Link
          onClick={() => setOpen(false)}
          to="/team"
          className={main ? "nav-link-main" : "nav-link"}
        >
          Team
        </Link>
        <Link
          onClick={() => setOpen(false)}
          to="/blog"
          className={main ? "nav-link-main" : "nav-link"}
        >
          Blog
        </Link>
        <Link
          onClick={() => setOpen(false)}
          to="/contact"
          className={main ? "nav-link-main" : "nav-link"}
        >
          Contact
        </Link>
      </div>
    </>
  );
};
export default NavList;
